<template>
  <NotPermission v-if="!authenticated" />

  <v-container v-else fluid grid-list-lg>
    <Breadcrumbs :routes="[{ name: 'Inicio' }, { name: 'Producto' }, { name: 'Edit Item' }]" />
    <v-card style="border-radius:10px 10px 0 0;">
      <v-toolbar card style="border-radius:10px 10px 0 0;" color="primary" dark>
        <v-toolbar-title></v-toolbar-title>
      </v-toolbar>
      <br />
      <v-form ref="form" v-model="validForm" lazy-validation @submit.prevent="submitAtributo">
        <v-container fluid grid-list-lg>
          <v-layout row wrap>
            <v-flex md12 sm12 xs12>
              <v-text-field v-model="form.nombre_campo" label="Nombre campo" outline />
            </v-flex>
            <v-flex md12 sm12 xs12>
              <v-text-field v-model="form.valor_campo" label="Valor Campo" outline />
            </v-flex>
            <v-flex md12 sm12 xs12>
              <v-autocomplete
                v-model="form.mostrar"
                :items="estados"
                dense
                outline
                clearable
                small-chips
                label="Mostrar"
                item-text="nombre"
                item-value="id"
              />
            </v-flex>
            <v-flex sm12 xs12>
              <v-autocomplete
                v-model="form.clasificacion_id"
                :items="clasificaciones"
                dense
                outline
                clearable
                small-chips
                label="Clasificación"
                item-text="nombre"
                item-value="id"
              />
            </v-flex>
            <v-flex md12 sm12 xs12>
              <v-autocomplete
                v-model="form.tipo_campo"
                :items="tipos"
                dense
                outline
                clearable
                small-chips
                label="Tipo Campo"
                item-text="texto"
                item-value="id"
              />
            </v-flex>
          </v-layout>
        </v-container>
        <center>
          <v-btn
            type="submit"
            color="success"
            :disabled="!validForm || processingForm"
            :loading="processingForm"
            >Guardar</v-btn
          >
          <v-btn
            color="error"
            @click="$router.push({ name: 'ListaProductAtributo', params: { id: id } })"
          >
            Volver
          </v-btn>
        </center>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  metaInfo() {
    return { title: "Editar Atributo" };
  },

  components: {
    NotPermission: () => import("@/views/errors/NotPermission"),
    Breadcrumbs: () => import("@/components/Breadcrumbs")
  },
  data() {
    return {
      formErrors: {},
      activeBtn: 2,
      producto: "",
      form: {
        nombre_campo: "",
        valor_campo: "",
        mostrar: false,
        tipo_campo: "",
        clasificacion_id: ""
      },
      id: "",
      tipos: [
        { id: "INT", texto: "Entero" },
        { id: "DOUBLE", texto: "Decimal" },
        { id: "TEXT", texto: "Texto" },
        { id: "CURRENCY", texto: "Moneda" }
      ],
      estados: [
        { id: 1, nombre: "Si" },
        { id: 0, nombre: "No" }
      ],
      clasificaciones: [],
      validForm: true,
      processingForm: false,
      rules: {
        nombre_campo: [v => !!v || "El nombre campo es requerido"]
      }
    };
  },
  computed: {
    ...mapState({
      currentProducto: state => state.productos.currentProducto
    }),
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user"
    })
  },
  created() {
    this.getAtributo({ atributoId: this.$route.params.id }).then(response => {
      const itemInfo = response.data.data;
      this.setForm(itemInfo);
    });
    this.getProducto({ productoId: this.id }).then(response => {
      this.producto = response.data.data;
    });
    this.getClasificaciones().then(res => {
      this.clasificaciones = res.data.data;
    });
  },
  methods: {
    ...mapActions({
      getAtributo: "productos/getAtributo",
      updateAtributo: "productos/updateAtributo",
      getProducto: "productos/getProducto",
      getClasificaciones: "clasificaciones/getClasificaciones"
    }),
    setForm(item) {
      this.form.nombre_campo = item.nombre_campo;
      this.form.valor_campo = item.valor_campo;
      this.form.mostrar = item.mostrar;
      this.form.tipo_campo = item.tipo_campo;
      this.form.clasificacion_id = item.clasificacion_id;
      this.id = item.producto_id;
    },
    submitAtributo() {
      if (!this.$refs.form.validate()) return false;
      this.processingForm = true;
      this.updateAtributo({ atributoId: this.$route.params.id, data: this.form })
        .then(response => {
          this.processingForm = false;
          // this.$router.push({ name: "ListaProductAtributo", params: { id: this.id } });
        })
        .catch(error => {
          this.processingForm = false;
        });
    }
  }
};
</script>
